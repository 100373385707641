// Standard library import
import React from 'react';

// External library imports
import Chart from 'react-apexcharts';
import { useHistory } from 'react-router-dom';

// Internal module imports
import Typography from '../Typography/Typography';
import { usToEuCurrencyFormat } from '../../utils/currencyHelper';

// Css imports
import classes from './Chart.module.css';

const getMaxValue = (datas) => {
    let firstHigh = 0;
    let secondHigh = 0;

    for (const data in datas) {
        const { firstHighest, secondHighest } = datas[data].data.reduce(
            (acc, el) => {
                if (el.y > acc.firstHighest) {
                    acc.secondHighest = acc.firstHighest;
                    acc.firstHighest = el.y;
                } else if (el.y < acc.firstHighest && el.y > acc.secondHighest) {
                    acc.secondHighest = el.y;
                }
                return acc;
            },
            { firstHighest: 0, secondHighest: 0 }
        );
        if (firstHighest > firstHigh) {
            firstHigh = firstHighest;
            secondHigh = secondHighest;
        }
    }
    return !firstHigh ? 1 : firstHigh && !secondHigh ? firstHigh + (firstHigh * 0.1) : firstHigh + (firstHigh - secondHigh);
};

const ChartComponent = ({
    title,
    series,
    curve = 'smooth',
    colors,
    strokeWidth,
    markerSize,
    xFormatter = null,
    yFormatter = null,
    type,
    dataLabels = { enabled: false },
    disableZoom,
    annotations = null,
    xType = null,
    yProps = null,
    xProps = null,
    xLabelProps = null,
    linkTitle,
    legend = false,
    lastReported = null,
    chartProps = null,
    lastReportedStyle = {},
    rightSideElement = null,
    height = "80%",
    width = "98%"
}) => {
    const history = useHistory();
    const state = {
        options: {
            annotations: { position: 'front', ...annotations },
            chart: {
                id: 'basic-bar',
                zoom: {
                    enabled: !disableZoom,
                },
                ...chartProps,
            },
            colors,
            dataLabels,

            stroke: {
                show: true,
                curve: curve,
                lineCap: 'butt',
                width: strokeWidth >= 0 ? strokeWidth : 2,
                dashArray: 0,
                colors: colors,
            },
            dropShadow: {
                enabled: true,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.5,
                color: 'yellow',
            },
            markers: {
                size: markerSize || 0,
                colors: colors || ['#40D3E6'],
                strokeColors: colors || ['#40D3E6'],
                strokeWidth: 2,
                strokeOpacity: 0.9,
                strokeDashArray: 0,
                fillOpacity: 1,
                discrete: [],
                shape: 'circle',
                radius: 2,
                offsetX: 0,
                offsetY: 0,
                onClick: undefined,
                onDblClick: undefined,
                showNullDataPoints: true,
                hover: {
                    size: undefined,
                    sizeOffset: 3,
                },
            },
            xaxis: {
                type: xType || 'datetime',
                labels: {
                    formatter: xFormatter ? (value, timestamp, opts) => xFormatter(value) : (value, timestamp, opts) => value,
                    hideOverlappingLabels: true,
                    showDuplicates: false,
                    style: {
                        fontSize: '0.825vw',
                    },
                    ...xLabelProps,
                },
                ...xProps,
            },
            yaxis: {
                labels: {
                    formatter: yFormatter ? (value, timestamp, opts) => yFormatter(value) : (value, timestamp, opts) => usToEuCurrencyFormat(parseFloat(value).toFixed(2)),
                    style: {
                        fontSize: '0.825vw',
                    },
                },
                // ...(type === 'line' && series?.[0]?.data?.length ? { max: getMaxValue(series) } : {}),
                ...yProps,
            },
            tooltip: {
                style: {
                    fontSize: '1vw',
                },
            },
            legend: {
                show: legend ? true : false,
            },
        },
        series,
    };
    return (
        <div className={classes.ChartContainer}>
            <div className={classes.Head}>
                {linkTitle || lastReported || title ? (
                    <div>
                        {!linkTitle ? (
                            <div>
                                <div className={classes.Title}>{title}</div>
                                {lastReported ? (
                                    <div className={classes.lastReported} style={lastReportedStyle}>
                                        <Typography subtext content={'Last Reported: ' + (lastReported || ' - ')} />
                                    </div>
                                ) : null}
                            </div>
                        ) : (
                            <div className={classes.LinkTitle} onClick={() => history.push(linkTitle.link)}>
                                {linkTitle.name}
                            </div>
                        )}
                    </div>
                ) : (
                    <></>
                )}
                {rightSideElement && rightSideElement}
            </div>

            <Chart options={state.options} series={state.series} type={type || 'area'} height={height} width={width} />
        </div>
    );
};

export default ChartComponent;
