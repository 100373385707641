// Standard library imports
import React from 'react';
// Internal module imports
import Control from './Control';
import TabsComponent from '../../../../components/Tabs/Tabs';

const ScheduleTestIndex = ({ edgeData, setScheduleModal }) => {
    const tabs = [
        {
            name: 'Equipment Testing',
            component: (
                <>
                    <Control
                        edgeData={edgeData}
                        setScheduleModal={setScheduleModal}
                    />
                </>
            ),
        }
    ];

    return (
        <div>
            <TabsComponent selectedIndex={0} tabs={tabs} />
        </div>
    );
};

export default ScheduleTestIndex;
