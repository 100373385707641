import { HttpClient } from '../utils/httpClient';

const PATH = {
    readSignalDataFromSourceTest: '/client/signals/data-test-setpoint',
    lastReportedData: '/client/signals/lastReported',
};

const ReadSignalDataFromSourceTest = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.readSignalDataFromSourceTest, { params }).then(callback).catch(error).finally(next);
};

const getLastReportedData = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.lastReportedData, { params }).then(callback).catch(error).finally(next);
};

const AutoRefreshDataTest = (params, callback, error, next) => {
    return HttpClient.get(PATH.readSignalDataFromSourceTest, { params }).then(callback).catch(error).finally(next);
};

const autoRefreshLastReportedData = (params, callback, error, next) => {
    return HttpClient.get(PATH.lastReportedData, { params }).then(callback).catch(error).finally(next);
};

export const signalsService = {
    autoRefreshLastReportedData,
    AutoRefreshDataTest,
    ReadSignalDataFromSourceTest,
    getLastReportedData,
};
