import { HttpClient } from '../utils/httpClient';
import moment from 'moment';

const PATH = {
    alerts: 'client/data/alert',
    triggeredalerts: 'client/data/triggered',
    data: 'client/data',
    licenseType: 'client/license/type',
    licenseV2: 'client/license/v2',
    licenseTypes: 'client/license/type/v2',
    licenseById: 'client/license/licenseType',
};

const getAllAlerts = (type, id, start, callback, error, next) => {
    start();
    let params = {
        source: type,
    };
    return HttpClient.get(`${PATH.alerts}/all/${id}`, { params }).then(callback).catch(error).finally(next);
};

const getTriggeredAlerts = (id, date, start, callback, error, next) => {
    start();
    let params = {
        date,
    };
    return HttpClient.get(`${PATH.triggeredalerts}/${id}`, { params }).then(callback).catch(error).finally(next);
};

const createAlert = (id, type, payload, start, callback, error, next) => {
    start();
    let params = {
        source: type,
    };
    return HttpClient.post(`${PATH.alerts}/create/${id}`, { alert: payload }, { params }).then(callback).catch(error).finally(next);
};

const updateAlert = (id, type, payload, start, callback, error, next) => {
    start();
    let params = {
        source: type,
    };
    return HttpClient.patch(`${PATH.alerts}/update/${id}`, { alert: payload }, { params }).then(callback).catch(error).finally(next);
};

const deleteAlert = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.alerts}/${id}`).then(callback).catch(error).finally(next);
};

const getRevenue = (start, callback, error, next) => {
    start();
    const startDate = moment().startOf('month').format('YYYY-MM-DD');
    const endDate = moment().endOf('month').format('YYYY-MM-DD');

    return HttpClient.get(`${PATH.data}/revenue`, { params: { startDate, endDate } }).then(callback).catch(error).finally(next);
};

const getLicenseTypeDetails = (customerId, licenseTypeId, params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.licenseType}/${customerId}/${licenseTypeId}`, { params }).then(callback).catch(error).finally(next);
};

const getLicenseDetailsV2 = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.licenseV2}/${id}`).then(callback).catch(error).finally(next);
};

const getLicenseTypes = (start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.licenseTypes}`).then(callback).catch(error).finally(next);
};

const getLicenceById = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.licenseById}/${id}`).then(callback).catch(error).finally(next);
};

export const LicenseService = {
    getAllAlerts,
    getTriggeredAlerts,
    createAlert,
    deleteAlert,
    updateAlert,
    getRevenue,
    getLicenseTypeDetails,
    getLicenseDetailsV2,
    getLicenseTypes,
    getLicenceById,
};
