import React, { useContext, useEffect, useState } from 'react';
import Typography from '../../../components/Typography/Typography';
import Table from '../../../components/Table/Table';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';
import { START_LOADER, STOP_LOADER, USER_ROLE } from '../../../constants';
import DownloadButton from '../../../components/Buttons/DownloadButton';
import { LicenseService } from '../../../services/LicenseService';
import { LoaderContext } from '../../../context/LoaderContext';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';
import moment from 'moment';
import { findDiffInMinutes, momentTimeFormater } from '../../../utils/dateHelper';
import { UilCheck, UilTimes, UilStopwatch } from '@iconscout/react-unicons';
import { AuthContext } from '../../../context/AuthContext';
import { get as LodashGet } from 'lodash';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import ScheduleTestIndex from './ModalComponent/ScheduleTestIndex';

// Css imports
import classes from '../../../styles/AllDevices.module.css';

const AllInstances = ({ devices }) => {
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });

    const { state } = useContext(AuthContext);
    const userRole = LodashGet(state, 'user.userRole');

    const [instances, setInstances] = useState([]);
    const [scheduleModal, setScheduleModal] = useState({
        status: false,
        data: ''
    });

    useEffect(() => {
        fetchLicenseTypes();
    }, []);

    const handleError = (err) => {
        console.log(err);
    };

    const handleGetLicenseTypesSuccess = ({ data }) => {
        const licenseTypesHash = {};
        data?.data.forEach((type) => {
            licenseTypesHash[type._id] = type;
        });

        const processedData = devices.map((instance, index) => {
            const edgeLastReportedDifference = instance?.edgeLastReported ? findDiffInMinutes(instance?.edgeLastReported, moment()) : 16;
            const edge = instance.edgeDetails || {};
            const signalData = instance.signalData || {};

            return {
                's.no': index + 1,
                name: instance.name,
                description: !instance?.description ? (
                    '--'
                ) : instance.description.length > 40 ? (
                    <CustomTooltip content={instance.description}>{instance.description.substring(0, 40) + '...'}</CustomTooltip>
                ) : (
                    instance.description
                ),
                licenseType: licenseTypesHash[instance.licenseId].name,
                market: instance?.market?.length ? instance.market.join(',') : '--',
                id: instance._id,
                edgeLastReported: edgeLastReportedDifference < 15 ?
                    (
                        <UilCheck size="1.5vw" style={{ color: 'green' }} />
                    ) : (
                        <UilTimes size="1.5vw" style={{ color: 'red' }} />
                    ),
                bidActive: instance.bidActive ?
                    (
                        <UilCheck size="1.5vw" style={{ color: 'green' }} />
                    ) : (
                        <UilTimes size="1.5vw" style={{ color: 'red' }} />
                    ),
                action: (
                    <div onClick={() => {
                        setScheduleModal({
                            status: true,
                            data: { deviceId: edge.deviceId, market: edge.market, signalId: signalData?.[0]?._id },
                        });
                    }}>
                        <CustomTooltip content={'Equipment Testing'}>
                            <UilStopwatch size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CustomTooltip>
                    </div>
                )
            };
        });
        setInstances(processedData);
    };

    const fetchLicenseTypes = () => {
        LicenseService.getLicenseTypes(
            () => startLoader('getInstances'),
            handleGetLicenseTypesSuccess,
            handleError,
            () => stopLoader('getInstances')
        );
    };

    const handleExport = () => {
        const processedData = [];
        devices.map((device) => {
            processedData.push({
                groupName: device.name,
                frequencySignal: device.populatedFrequency?.props.children,
                fcr: device.properties?.fcr?.enabled,
                decreased: device.properties?.fcr?.decreased,
                increased: device.properties?.fcr?.increased,
                groupId: device._id,
            });
        });
        DownloadAsExcel(processedData, 'Generic_Instance', ['Instance Name', 'Frequency Signal', 'FCR', 'Decreased', 'Increased', 'Instance ID']);
    };
    return (
        <div className={classes.AllDevices}>
            <div className={classes.Header}>
                <div>
                    <Typography content="All Instances" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={devices.length} />
                        </span>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <DownloadButton size="medium" onClick={() => handleExport()} />
                    </div>
                </div>
            </div>
            <div>
                <Table
                    head={['S.No', 'Name', 'Description', 'License Type', 'Market', 'Market Status', 'Edge Status',
                        ...(userRole === USER_ROLE.SUDOER ? ['Action'] : [])
                    ]}
                    keys={['s.no', 'name', 'description', 'licenseType', 'market', 'bidActive', 'edgeLastReported',
                        ...(userRole === USER_ROLE.SUDOER ? ['action'] : [])
                    ]}
                    data={instances}
                />
            </div>

            <ModalComponent isOpen={scheduleModal.status} setOpen={() => { }}>
                <div>
                    <ScheduleTestIndex
                        edgeData={scheduleModal.data}
                        setScheduleModal={setScheduleModal}
                    />
                </div>
            </ModalComponent>
        </div>
    );
};

export default AllInstances;
