// Internal module import
import { HttpClient } from '../utils/httpClient';

const PATH = {
    getEdgeDevices: '/client/edge-device',
    control: '/client/iot-device/control',
};

const getEdgeDevices = (start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getEdgeDevices}`).then(callback).catch(error).finally(next);
};

const SendControlData = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.control, payload).then(callback).catch(error).finally(next);
};

export const EdgeDeviceService = {
    getEdgeDevices,
    SendControlData,
};
